<template>
  <v-snackbar
    top
    right
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :multi-line="snackbar.multiline"
    :color="snackbar.color"
    elevation="2"
    style="margin-top: 5em"
    rounded="lg"
    max-width="400"
    outlined
    transition="scroll-x-reverse-transition"
  >
    <v-row class="d-flex align-items-center" justify="center">
      <v-col cols="10" class="pa-0">
        <p class="ma-0">{{ snackbar.text }}</p>
      </v-col>
      <v-col cols="1">
        <v-btn :color="snackbar.color" small fab depressed dark class="close-button" @click="closeAlert">
          <v-icon small>{{ snackbar.icon ? snackbar.icon :  'mdi-close-thick' }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  computed: {
    snackbar () {
      return this.$store.state.snackbar
    }
  },
  methods: {
    ...mapMutations(['closeAlert'])
  }
}
</script>
<style lang='scss' scoped>
.v-snack {
  .v-snack__wrapper {
    .v-snack__content {
      .close-button {
        height: 34px;
        width: 34px;
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>