/* eslint-disable operator-linebreak */
/* eslint-disable template-tag-spacing */
/* eslint-disable eol-last */
/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
import Vue from 'vue'
import Vuex from 'vuex'

// modules
import addAds from '../modules/addAds'
import general from '../modules/general'
import location from '../modules/location'
import utils from '../modules/utils'
import chat from '../modules/chat'
import { getField, updateField } from 'vuex-map-fields'
// import { publicitySquare, publicityLongRectangle, publicityTallRectangle } from '../assets/publicity-items/publicityItems'

import axios from 'axios'
import VueCookie from 'vue-cookie'
import { urlBase } from '../assets/js/base_url'

// eslint-disable-next-line no-unused-vars
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    dataAds: [],
    count: 0,
    categories: [],
    subcategories: [],
    section: '',
    component: [],
    infiniteId: +new Date(),
    snackbar: {
      visible: false,
      color: 'success',
      text: null,
      timeout: 5000,
      multiline: false,
      icon: null
    },
    collectionPublicitySquare: [],
    collectionPTallRectangle: [],
    collectionPLongRectangle: [],

      // DATOS DE INICIO DE SESION
      dataUser: [],
      userAuth: VueCookie.get('token') ? VueCookie.get('token') : null,

    getAds: {},
    infiniteLoading: {},
    isSearch: false
  },

  mutations: {
    setDataUser(state, payload) {
      state.dataUser = payload
    },
    DataEncryct (state, payload) {
      state.DataEncryct = payload
    },
    updateField,
    setcomponent(state, payload) {
      state.component = payload
    },
     loadNextAds(state, payload) {
      state.dataAds.push(...payload)
    },
    loadSearchAds(state, payload) {
      state.dataAds = payload
    },
    loadAds(state, payload) {
      if (!payload.data) {
        state.dataAds = payload
      } else {
        state.dataAds = payload.data
      }
    },
    setCategorias(state, payload) {
      state.categories = payload.data
    },
    getInfoUser(state, payload) {
      axios.get(`${urlBase()}profile/info/${payload}`).then((response) => {
        state.dataUser = response.data[0]
        localStorage.setItem(
          'userLogged',
          window.btoa(JSON.stringify(state.dataUser))
        )
      })
    },

    alert(state, payload) {
      state.snackbar.text = payload.text

      // Color para mensajes de error o éxito
      if (payload.color) {
        state.snackbar.color = payload.color
      }

      if (payload.icon) {
        state.snackbar.icon = payload.icon
      } else {
        state.snackbar.icon = null
      }

      // Tiempo de duración
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout
      }
      state.snackbar.visible = true
    },

    closeAlert(state) {
      state.snackbar.visible = false
      state.snackbar.multiline = false
      state.snackbar.text = null
    },
    setResetValue(state) {
      state.infiniteId += 1
      state.count = 0
      state.dataAds = []
      state.getAds = {}
    },
    resetDataAds(state) {
      state.count = 0
      state.dataAds = []
    },
    setGetads(state, payload) {
      state.count = 0
      state.dataAds = []
      state.getAds = {}
      state.getAds.subCategoria = payload
    },
    setServices(state, payload) {
      state.count = 0
      state.dataAds = []
      state.getAds = {}
      state.getAds.categoria = payload
    },
    setPublicity(state, { publicityLongRectangle, publicitySquare, publicityTallRectangle }) {
      state.collectionPublicitySquare = publicitySquare
      state.collectionPTallRectangle = publicityTallRectangle
      state.collectionPLongRectangle = publicityLongRectangle
    }
  },
  actions: {
    setcomponent({ commit }, value) {
      commit('setcomponent', value)
    },
    async loadAds({ commit, state }) {
      state.getAds.numItems = 0
      state.count = 1
      state.addAds = []
      const url =
        state.component === 'ads'
          ? 'anuncio/getItemsToGrid'
          : 'servicio/getItemsToGrid'
      const api = await axios.post(`${urlBase()}${url}`, state.getAds, {
        headers: {
          'content-type': 'text/json'
        }
      })
      state.getAds.numItems = state.count * 16
      if (api.data.length) {
        state.infiniteLoading.loaded()
      } else {
        state.infiniteLoading.complete()
      }
      commit('loadAds', api)
    },
    handleGetPais({ commit }) {
      commit('handleGetPais')
    },
    handleGetDpto({ commit }) {
      commit('handleGetDpto')
    },
    async setCategorias({ commit }, section) {
      const categorias = await axios.get(
        `${urlBase()}general/categoriasConSubCategorias`
      )
      commit('setCategorias', categorias)
    },
    getInfoUser({ commit }, payload) {
      commit('getInfoUser', payload)
    },

    setChangeType({ commit }) {
      commit('setChangeType')
    },
    async getPublicity({ commit }) {
      const publicity = await axios.get('https://publiktapp-dev.com/v1/getPublicity')
      console.log(publicity)
      commit('setPublicity', publicity.data)
      // commit('setPublicity', { publicitySquare, publicityLongRectangle, publicityTallRectangle })
    }
  },
  modules: {
    addAds,
    location,
    utils,
    general,
    chat
  },

  getters: {
    getField,

      getUserLogged(state) {
        return state.userAuth !== null
      }
    }
})
