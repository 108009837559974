/* eslint-disable operator-linebreak */
/* eslint-disable template-tag-spacing */
/* eslint-disable eol-last */
/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
// import { urlBase } from '../assets/js/base_url'
import axios from 'axios'
import Vue from 'vue'

// axios.defaults.baseURL = urlBase()

export default {
  namespaced: true,
  state: {
    countries: [],
    departments: [],
    cities: []
  },

  mutations: {
    setCountry (state, payload) {
      state.countries = payload
    },

    setDepto (state, payload) {
      state.departments = payload
    },

    setCity (state, payload) {
      state.cities = payload
    }
  },

  actions: {
    async getCountry ({ commit }) {
      try {
        const resp = await axios.get('general/countries')
        const countries = await resp.data
        commit('setCountry', countries)
      } catch (error) {
        console.log(error)
      }
    },

    async getDepto ({ commit }, paisId) {
      try {
        const resp = await Vue.prototype.services.getDepartamento(paisId)
        const departments = await resp.data
        commit('setDepto', departments)
      } catch (error) {
          console.log(error)
      }
    },

    async getCity ({ commit }, departamentId) {
        try {
          const resp = await Vue.prototype.services.getMunicipio(departamentId)
          const cities = await resp.data
          commit('setCity', cities)
        } catch (error) {
          console.log(error)
        }
    }
  }
}
