<template>
  <section class="plantillaLayout">
    <NavbarResponsive />
    <slot class="contentMain"></slot>
    <MenuMobil v-if="this.$vuetify.breakpoint.width < 959" />
    <v-footer v-show="showFooter">
      <Footer />
    </v-footer>
  </section>
</template>

<script>
import Footer from '@/components/Footer'
import NavbarResponsive from '@/components/NavbarResponsive'
import MenuMobil from '@/components/MenuMobil'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'PlantillaLayout',
  data: () => ({}),
  components: {
    Footer,
    NavbarResponsive,
    MenuMobil
  },
  methods: {
    ...mapActions(['setCategorias']),
    ...mapMutations(['setDataUser'])
  },
  computed: {
    ...mapState(['token']),
    // eslint-disable-next-line vue/return-in-computed-property
    showFooter () {
      // Mostrar/ocultar footer
      if (this.$route.path === '/publications/ads' || this.$route.path === '/publications/services' || this.$route.path === '/producto/Productos' || this.$route.path === '/chat') {
        return false
      } else {
        return true
      }
    }
  },
  async created () {
    this.setCategorias()
    if (localStorage.getItem('userLogged')) {
      const dataEncryct = this.token || JSON.parse(window.atob(localStorage.getItem('userLogged')))
      if (dataEncryct) {
        this.setDataUser(dataEncryct)
      }
    }
  }
}
</script>

<style scoped>
.nav-columm {
  text-align: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ns-logo {
  width: 6em;
}
</style>