import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

// eslint-disable-next-line eol-last
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#CC33CC'
      }
    }
  }
})