/* eslint-disable func-call-spacing */
/* eslint-disable space-in-parens */
/* eslint-disable indent */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Publications from '@/views/Publications.vue'
import VueCookie from 'vue-cookie'
import routerChat from '@/views/chat/router'

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      name: 'Inicio',
      component: () =>
          import ('../views/Inicio')
  },
  {
      path: '/about',
      name: 'About',
      component: () =>
          // eslint-disable-next-line func-call-spacing
          import ( /* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
      path: '/login',
      name: 'login',
      component: () =>
          import ('../views/Login')
  },
  {
      path: '/producto/:action',
      name: 'Productos',
      meta: { guard: true },
      component: () =>
          import ('../views/PostProducts')
  },
  {
    path: '/producto/:action/:id',
    name: 'Productos2',
    meta: { guard: true },
    component: () =>
        import ('../views/PostProducts')
  },
  {
    path: '/verify/otp',
    name: 'CodeOtp',
    component: () => import ('@/views/CodeOtp')
  },

  // RUTAS GRID ANUNCIOS
  {
      path: '/publications/:ads',
      name: 'Publications',
      component: Publications
  },
  {
      path: '/detalle/Anuncio/:id',
      name: 'detalleAnuncio',
      component: () =>
          import ('../views/DetailsAd')
  },

  // FIN RUTAS GRID ANUNCIOS

  // RUTAS GRID SERVICIOS
  {
      path: '/publications/:services',
      name: 'Publications2',
      component: Publications
  },

  {
      path: '/detalle/Servicio/:id',
      name: 'detalleServicio',
      component: () =>
          import ('@/views/DetailService')
  },

  // FIN RUTAS GRID SERVICIOS

  // RUTAS DE PERFIL EN SESION
  {
      path: '/profile',
      name: 'Profile',
      meta: { guard: true },
      component: () => import ('../views/profile/HomeProfile')
  },
  {
    path: '/profile/favorites',
    name: 'FavoritePublication',
    meta: { guard: true },
    component: () => import ('@/views/profile/FavoritePublication')
  },
  {
    path: '/profile/my-posts',
    name: 'MyPost',
    meta: { guard: true },
    component: () => import ('@/views/profile/postPublications.vue')
  },
  {
    path: '/profile/info',
    name: 'Info',
    meta: { guard: true },
    component: () => import ('@/views/profile/PerfilUser')
  },
  // {
  //   path: '/profile/opinions',
  //   name: 'Opinions',
  //   meta: { guard: true },
  //   component: () => import ('@/views/profile/Opinions')
  // },
  //  {
  //   path: '/profile/favorites',
  //   name: 'Favorite',
  //   meta: { guard: true },
  //   component: () => import ('@/views/profile/Favorites')
  // },

  // FIN  RUTAS PERFIL EN SESION

  // RUTAS PERFIL DE TERCEROS

  {
    path: '/profileThrid/:id',
    name: 'ProfileThrid',
    // meta: { guard: true },
    component: () => import ('@/views/profileThrid/HomeProfile')
  },

  {
    path: '/privacyPolicies',
    name: 'privacyPolicies',
    // meta: { guard: true },
    component: () => import ('@/views/privacyPolicies.vue')
  },

  {
    path: '/terms&conditions',
    name: 'termsConditions',
    // meta: { guard: true },
    component: () => import ('@/views/termsConditions.vue')
  },

  // FIN RUTAS PERFIL TERCEROS

  ...routerChat

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//   if (to.meta.guard) {
//     if (store.getters.getUserLogged) {
//       next()
//     } else {
//       next('/Login')
//     }
//   } else {
//     next()
//   }
// })

router.beforeEach(async (to, from, next) => {
  if (to.meta.guard) {
    const token = VueCookie.get('token')
    if (!token) {
      next('/login')
      return
    }
  }
  next()
})

// eslint-disable-next-line eol-last
export default router