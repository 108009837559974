<template>
  <v-app>
    <PlantillaLayout>
      <router-view />
      <Snackbar />
    </PlantillaLayout>
  </v-app>
</template>

<script>
import PlantillaLayout from '@/views/layout/PlantillaLayout'
import Snackbar from '@/components/Snackbar'
import { mapState, mapActions } from 'vuex'
import VueCookie from 'vue-cookie'
export default {
  components: {
    PlantillaLayout,
    Snackbar
  },

  data () {
    return {
      subscription: null
    }
  },

  watch: {
    dataUser () {
      if (this.dataUser.length !== 0) {
        this.socket.emit('join-room', this.dataUser?.IdUsuario)
      }
    },
    $route (to, from) {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      })
      this.isCompleteData()
    }
  },

  computed: {
    ...mapState(['dataUser'])
  },

  methods: {
    ...mapActions('general', ['setDpto']),
    ...mapActions(['getPublicity']),
    // Check if the user data is complete
    isCompleteData () {
      // If session exist
      const token = VueCookie.get('token')
      if (token) {
        // If variables of location ans sex exist
        if (!('IdPais' in this.dataUser) || this.dataUser.IdSexo == null) {
          this.$router.replace({ path: '/profile' }, () => {
            this.$router.go(0)
          })
        }
      }
    },
    urlBase64ToUint8Array (base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    },

    async swSubscription () {
      try {
        const register = await navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
        this.subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(this.publicVapidKey)
        })
        this.socket.emit('subscription', JSON.stringify(this.subscription))
      } catch (error) {}
    },

    /**
     * @param {String} payload
     * @Description Recibe como parametro el nombre del usuario de quien a enviado el mensaje
     */
    notification (payload) {
      this.socket.emit('inbox', this.dataUser.IdUsuario)
      // if (document.hidden) {
      payload.urlPhoto = this.dataUser.urlFoto

      const data = {
        subscription: this.subscription,
        ...payload
      }
      this.socket.emit('pushNotification', data)
      // }

      this.$store.commit('alert', {
        multiline: true,
        text: `Tienes un nuevo mensaje de ${payload.usuario}`,
        color: '#17a2b8',
        icon: 'mdi-email-check'
      })
    }
  },

  async created () {
    await this.getPublicity()
    this.setDpto(1)
    // this.socket.on('notification', (usuario) => this.notification(usuario))
    // soporte de service worker
    if ('serviceWorker' in navigator) {
      this.swSubscription()
    }
    this.socket.on('notification', usuario => this.notification(usuario))
  }
}
</script>

<style lang="scss">
body {
  overflow: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(165, 165, 165);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cc33cc;
}
::-webkit-scrollbar-track {
  background: #f2f2f2;
}
</style>