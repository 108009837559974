/* eslint-disable indent */
// eslint-disable-next-line space-before-function-paren
function url(url) {
  let protocolo
  if (location.protocol === 'https:') {
    protocolo = location.protocol + '//publikt.store/'
    protocolo = location.protocol + '//publiktapp-api-dev.com/'
  } else {
    protocolo = location.protocol + '//localhost:8080/publikt/'
  }
  return protocolo + url
}

// eslint-disable-next-line space-before-function-paren
function urlBase() {
  // const urlApi = 'https://publiktstore.com/api/'
  const urlApi = 'https://publiktapp-api-dev.com/api/'
  // const urlApi = 'http://localhost:8000/api/'
  return urlApi
}

// eslint-disable-next-line eol-last
export { url, urlBase }