import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    country: [],
    departamento: [],
    municipio: []

  },
  mutations: {
    setDpto (state, payload) {
      state.departamento = payload.data
    },
    setContry (state, payload) {
      state.country = payload.data
    },
    setMunicipio (state, payload) {
      state.municipio = payload.data
    }
  },
  actions: {
    async setContry ({ commit }) {
      const response = await Vue.prototype.services.getPais()
      commit('setContry', response)
    },
    async setDpto ({ commit }, country) {
      const response = await Vue.prototype.services.getDepartamento(country)
      commit('setDpto', response)
    },
    async setMunicipio ({ commit }, dpto) {
      const response = await Vue.prototype.services.getMunicipio(dpto)
      commit('setMunicipio', response)
    }
  }
}