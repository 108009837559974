import Vue from 'vue'
import router from '../router/index'
import store from '../store'
import { app } from '@/firebase'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { setTimeout } from 'core-js/web/timers'
Vue.prototype.temporalAlert = async (alert) => {
  await store.commit('alert', alert)
}

const addPhoto = async (files, infoUSer, refCarpeta) => {
  const storage = getStorage(app)
  var url = []
  var rename = []
  var i = 0
  var id = infoUSer.infoAnuncio.IdAnuncio || infoUSer.infoAnuncio.IdServicio
  for (const key in files) {
    // for await (const el of files) {}
    if (Object.hasOwnProperty.call(files, key)) {
      const el = files[key]
      if (el?.local) {
        rename.push(el.name.replaceAll(el.name, `photo_${el.lastModified}__product_id_${id}_user_id_${infoUSer.userId}`))
        const mountainImagesRef = ref(storage, `${refCarpeta}/${infoUSer.userId}/${id}/${rename[i]}`)
        const metadata = { contentType: el.type }
        const response = await uploadBytesResumable(mountainImagesRef, el, metadata)
        if (response.state === 'success') {
          const urlimg = await getDownloadURL(mountainImagesRef)
          url.push(urlimg.replace(rename[i], `${rename[i]}_700x700`))
        }
        i++
      }
    }
  }
  return { url, rename, id, refCarpeta }
}

const deletePhoto = async (infoUSer, refCarpeta, name) => {
  const storage = getStorage(app)
  const deleteImg = ref(storage, `${refCarpeta}/${infoUSer.userId}/${infoUSer.id}/${name}_700x700`)
  const deleteimgs = await deleteObject(deleteImg)
  return deleteimgs
}

const loaderStatus = () => {
  // eslint-disable-next-line no-undef
  bodymovin.loadAnimation({
    animationData: require('../assets/animation/dotLoader.json'),
    container: document.getElementById('animationLoader'),
    path: 'nombre_animacion.json',
    renderer: 'svg', // Required
    loop: true, // Optional
    autoplay: true, // Optional
    name: 'loader' // Name for future reference. Optional.
  })
  const loader = document.getElementById('contentLoader')
  if (!loader.classList.contains('showLoader')) {
    loader.classList.add('showLoader')
  }
}
const successAddProduct = () => {
  const animationContent = document.getElementById('animationLoader')
  animationContent.classList.add('successAnimation')
  const textContent = document.getElementById('textLoader')
  animationContent.innerHTML = ''
  textContent.innerHTML = '<p id="textLoader" class="text-loader">¡Publicado!</p>'
  // eslint-disable-next-line no-undef
  bodymovin.loadAnimation({
    animationData: require('../assets/animation/success.json'),
    container: document.getElementById('animationLoader'),
    path: 'nombre_animacion.json',
    renderer: 'svg', // Required
    loop: false, // Optional
    autoplay: true, // Optional
    name: 'success' // Name for future reference. Optional.
  })
  setTimeout(() => {
    router.push({ name: 'MyPost' })
  }, 4000)
}

Vue.prototype.firebaseImg =
{
  addPhoto,
  deletePhoto,
  loaderStatus,
  successAddProduct
}