/* eslint-disable eol-last */
/* eslint-disable indent */
import './plugins/services'
import './plugins/http'
import './plugins/authFB'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins/utils'
import router from './router'
import store from './store'
import InfiniteLoading from 'vue-infinite-loading'
// import { initFacebookSdk } from './views/initLoginFacebook'

import VueCookie from 'vue-cookie'

import moment from 'moment'
import 'moment/locale/es'
import GAuth from 'vue-google-oauth2'
import { io } from 'socket.io-client'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { regComponents } from '@/plugins/loadComponents'
// Gtag analitics
import VueGtag from 'vue-gtag'
regComponents(Vue)
Object.defineProperty(Vue.prototype, '$moment', {
  value: moment
})

const gauthOption = {
  clientId: '20456805636-vqtet746fcr7ecer8ta4vu5q2bf8nu7m.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.prototype.socket = io(process.env.VUE_APP_WS, {
  transports: ['websocket']
  // withCredentials: true
})

Vue.prototype.publicVapidKey = 'BEdY1AqkFlzA9EgvN5DkwRObi4RohnEwQiID1Qc8orJ_4W3c96RYdRywMKX2XaugbFHxT-Y7elPiT4XMmlmKZj4'

Vue.config.productionTip = false
Vue.use(InfiniteLoading, {})
// initFacebookSdk()

Vue.use(GAuth, gauthOption)
Vue.use(VueCookie)
Vue.use(InfiniteLoading, { /* options */ })
Vue.use(AOS.init())
Vue.use(VueGtag, {
  config: {
    id: 'G-9Q2TG9R5LC'
  }
}, router)

new Vue({
  vuetify,
  router,
  store,
  InfiniteLoading,
  AOS,
  render: (h) => h(App)
}).$mount('#app')
