import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    userInbox: [],
    messages: [],
    headerChat: {}, // { photo: null, name: null, userId: null },
    toUser: null
  },

  mutations: {
    setInboxUser (state, payload) {
      state.userInbox = []
      state.userInbox = payload
    },

    setHeaderChatBox (state, payload) {
      state.headerChat = payload
    },

    setMessages (state, payload) {
      if (payload.length < 1) {
        state.messages = []
        return
      }

      payload.forEach(item => {
        item.msg = decodeURIComponent(window.atob(item.msg))
      })

      state.messages = payload
    },

    setToUser (state, payload) {
      state.toUser = payload
    }
  },

  actions: {
    /**
     * @description Funcion para solicitar informacion de los usuarios con los que se a chateado
     * @param {Array} data
     */
    async getinfoUsuarioInbox ({ commit }, data) {
      const resp = await Vue.prototype.services.getUserInbox({ id_users: data })
      resp.data.forEach(user => {
        user.nombreCompleto = `${user?.nomUsuario} ${user?.apellidoUsuario !== undefined ? user.apellidoUsuario : ''}`
      })
      commit('setInboxUser', resp.data)
    }
  }
}