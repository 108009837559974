<template>
  <div class="navbar-content">
    <v-app-bar class="navbar-main" :elevation="2">
      <!-- Menu mobil -->
      <v-app-bar-nav-icon style="margin:auto; margin-left:-1em;" v-if="this.$vuetify.breakpoint.width < 959">
        <v-btn @click="drawer = true" icon color="#cc33cc">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <!-- Logo -->
      <router-link style="margin:auto;" to="/">
        <img class="logo-publikt" :src="require('@/assets/img/logoPurpura.png')" alt="logo" />
      </router-link>
      <!-- Search input -->
      <SearchInput />
      <!-- Action buttons -->
      <ActionsBtn style="margin:auto" v-if="this.$vuetify.breakpoint.width > 959" />
    </v-app-bar>
    <!-- Open option mobil -->
    <v-navigation-drawer  height="16em" width="100%" v-model="drawer" absolute temporary floating>
      <ActionsBtn />
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'NavbarResponsive',
  components: {
    ActionsBtn: () => import('./ComponentsNavbarResponsive/ActionsBtn.vue'),
    SearchInput: () => import('./ComponentsNavbarResponsive/SearchInput.vue')
  },
  data: () => ({
    // Variable to open/close the mobil menu
    drawer: false
  }),
  methods: {},
  computed: {
    ...mapState(['dataUser'])
  }
}
</script>
<style lang="scss" scoped>
.logo-publikt{
  width: 5em;
  margin-left:-1.5em;
}
.navbar-content{
    position: fixed;
    width: 100%;
    top: 0em;
    z-index: 99;
  }
@media (min-width: 960px) and (max-width: 4096px){
  .logo-publikt{
  width: 7em;
  margin-left:0em;
}
}
</style>