<template>
  <v-container fluid class="content-grid">
        <v-row>
          <v-col cols="3" ref="colSidebar">
              <div class="d-none d-md-flex px-0" style="position:fixed" :style="colWidth">
                <SidebarCategory :section="section" v-on="gridShow($route.params.ads)"/>
              </div>
          </v-col>
          <v-col xl="9" lg="9" md="9" sm="12">
            <component :section="section" :is="gridId"></component>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    category: String
  },
  components: {
    services: () => import('@/components/componentService/Grid'),
    ads: () => import('@/components/componentsAdd/Grid'),
    SidebarCategory: () => import('@/components/SidebarCategory')
  },
  data () {
    return {
      gridId: '',
      section: '',
      colWidth: ''
    }
  },
  methods: {
    ...mapActions(['setcomponent']),
    gridShow (val) {
      this.setcomponent(val)
      this.section = val
      this.gridId = val
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.$store.commit('setResetValue')
      }
    }
  },
  mounted () {
    this.colWidth = `width:${this.$refs.colSidebar.clientWidth}px;`
  }
}
</script>
<style scoped lang="scss">
.content-grid {
  margin-top: 2em;
}
@media (min-width: 600px) {
  .content-grid {
    margin-top: 3em;
  }
}

@media (min-width: 960px) {
  .content-grid {
    margin-top: 4em;
  }
  @media (min-width: 1264px) {
    .content-grid{
      max-width: 1920px;
    }
  }
}</style>