import Vue from 'vue'
// import store from '../store'
import axios from 'axios'

const http = async (
  url,
  data = {},
  method = 'get'
//   headers = {},
//   sendToken = true
) => {
//   const token = store.state.token
//     ? store.state.token
//     : localStorage.getItem('token')

  //   if (token && sendToken) headers.Authorization = 'Bearer ' + token

  const config = {
    baseURL: process.env.VUE_APP_API_URL || 'http://localhost:8000/api/',
    method,
    url
    // headers
  }
  if (method === 'get') {
    config.params = data
  } else {
    config.data = data
  }
  try {
    return await axios(config)
  } catch (e) {
    if (e.response.status === 500) {
      Vue.prototype.temporalAlert({
        text: e.response.data.msg ||
        'Ha ocurrido un error interno',
        color: 'error',
        timeout: 5000,
        multiline: true
      })
    } else if (e.response.status === 422) {
      Vue.prototype.temporalAlert({
        text: e.response.data.msg ||
        'No se pudo procesar la entidad',
        color: 'error',
        timeout: 5000,
        multiline: true
      })
    } else if (e.response.status === 404) {
      Vue.prototype.temporalAlert({
        text: e.response.data.msg ||
        'No se encontró el recurso',
        color: 'error',
        timeout: 5000,
        multiline: true
      })
    } else if (e.response.status === 403) {
      Vue.prototype.temporalAlert({
        text: e.response.data.msg ||
        'Petición rechazada',
        color: 'error',
        timeout: 5000,
        multiline: true
      })
    } else if (e.response.status === 400) {
      Vue.prototype.temporalAlert({
        text: e.response.data.msg ||
        'Petición erronea',
        color: 'error',
        timeout: 5000,
        multiline: true
      })
    } else if (e.response.status === 401) {
      Vue.prototype.temporalAlert({
        text: e.response.data.msg ||
        'Acceso no autorizado',
        color: 'error',
        timeout: 5000,
        multiline: true
      })
    } else {
      Vue.prototype.temporalAlert({
        show: true,
        message: e.response.data.message ||
          e.response.data.description ||
          'Error al realizar petición',
        type: 'error'
      })
    }
    throw e
  }
}

Vue.prototype.http = http

export { http }