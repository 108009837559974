// import { firebase, initializeApp } from '@firebase/app'
// import 'firebase/firestore'
// import 'firebase/storage'
import { initializeApp } from 'firebase/app'

// const firebaseConfig = {
//   apiKey: 'AIzaSyC-kS8u56T8rd8relV8KMTRfcHjAnKe-oQ',
//   authDomain: 'publikt-e9442.firebaseapp.com',
//   projectId: 'publikt-e9442',
//   storageBucket: 'publikt-e9442.appspot.com',
//   messagingSenderId: '20456805636',
//   appId: '1:20456805636:web:18bb8fb0d3d76a54712c51',
//   measurementId: 'G-3YGM5GTSED'
// }
const firebaseConfig = {
  apiKey: 'AIzaSyC-kS8u56T8rd8relV8KMTRfcHjAnKe-oQ',
  authDomain: 'publikt-e9442.firebaseapp.com',
  databaseURL: 'https://publikt-e9442-default-rtdb.firebaseio.com',
  projectId: 'publikt-e9442',
  storageBucket: 'publikt-e9442.appspot.com',
  messagingSenderId: '20456805636',
  appId: '1:20456805636:web:18bb8fb0d3d76a54712c51',
  measurementId: 'G-3YGM5GTSED'
}

const app = initializeApp(firebaseConfig)

// const db = firebase.firestore()
// const storage = firebase.storage()

export { app }