import Vue from 'vue'
import { http } from './http'

const getPais = async (params = {}) => {
  return await http('general/countries', params, 'get')
}
const getDepartamento = async (ciudad, params = {}) => {
  return await http(`general/departments/${ciudad}`, params, 'get')
}
const getMunicipio = async (municipio, params = {}) => {
  return await http(`general/municipality/${municipio}`, params, 'get')
}
const getBusquedaAnuncio = async (params = {}) => {
  return await http('general/busquedAnuncio', params, 'get')
}
const getCheckfavorite = async (ad, params = {}) => {
  return await http(`anuncio/findFavorite/${JSON.stringify(ad)}`, params, 'get')
}
const setAddFavorite = async (params = {}) => {
  return await http('anuncio/insertFavoritoAnuncio', params, 'post')
}
const getSubCategory = async (params = {}) => {
  return await http('landing/showSUbCategory', params, 'get')
}
const getMostadds = async (params = {}) => {
  return await http('landing/mostAdds/0', params, 'get')
}
const getServices = async (params = {}) => {
  return await http('landing/mostService/0', params, 'get')
}

const updateAdditionalInfo = async (id, params = {}) => {
  return await http(`/profile/updateAdditionalInfo/${id}`, params, 'put')
}

const getAdsPosted = async (infoUser, params = {}) => {
  return await http(`/profile/adsPosted/${infoUser}`, params, 'get')
}
const getServicesPosted = async (infoUser, params = {}) => {
  return await http(`/profile/servicesPosted/${infoUser}`, params, 'get')
}
const getfavoriteAds = async (infoUser, params = {}) => {
  return await http(`/profile/favoriteAds/${infoUser}`, params, 'get')
}
const getDatailAd = async (idAd, params = {}) => {
  return await http(`/anuncio/showDetailAnuncio/${idAd}`, params, 'get')
}
const getGalleryAds = async (idAd, params = {}) => {
  return await http(`/anuncio/showAdsGallery/${idAd}`, params, 'get')
}
const getGalleryService = async (idService, params = {}) => {
  return await http(`/servicio/showAdsGallery/${idService}`, params, 'get')
}
const getRating = async (idAd, params = {}) => {
  return await http(`/startRating/${idAd}`, params, 'get')
}
const setchangeStatusPublication = async (params = {}) => {
  return await http('general/publication/changeStatus', params, 'put')
}
const datailAd = async (idAd, idUsuario) => {
  return await http(`/anuncio/detailAnuncio/${idAd}/usuario/${idUsuario}`, 'get')
}
const getMunicipality = async (search, params = {}) => {
  return await http(`/general/searchMunicipality/${search}`, params, 'get')
}
const setAnuncio = async (path, params = {}) => {
  return await http(`/${path}`, params, 'post')
}
const deleteFhot = async (id, params = {}) => {
  return await http(`/anuncio/deletePhotoAd/${id}`, params, 'delete')
}

// AUTH
const login = async (params = {}) => {
  return await http('login', params, 'post')
}

const register = async (params) => {
  return await http('/register', params, 'post')
}

const loginApi = async (params = {}) => {
  return await http('/login-api', params, 'post')
}

const forgotPassword = async (params = {}) => {
  return await http('forgotPassword/email', params, 'put')
}

const otpVerify = async (otp) => {
  return await http(`verify/otp/${otp}`, {}, 'get')
}

const passwordChangeRecovery = async (otp, params) => {
  return await http(`forgotPassword/password/${otp}`, params, 'put')
}

// OTHER_PROFILE
const getInfoOtherUser = async (params) => {
  return await http(`/otherProfile/info/${params}`, 'get')
}

const complaintReason = async (params = {}) => {
  return await http('/otherProfile/complaint', params, 'get')
}

const createComplaintReason = async (params = {}) => {
  return await http('/otherProfile/complaint', params, 'post')
}

const createCommentary = async (params = {}) => {
  return await http('/otherProfile/commentary', params, 'post')
}

// chat
const getUserInbox = async (params) => {
  return await http('/user/inbox', params, 'get')
}

// FIN OTHER_PROFILE

const datailService = async (idService, idUsuario, params = {}) => {
  return await http(`/servicio/detailService/${idService}/usuario/${idUsuario}`, params, 'get')
}

const getMarcaSegunTipoVehiculo = async (idTipo, params = {}) => {
  return await http(`/anuncio/marcaVehicle/${idTipo}`, params, 'get')
}

Vue.prototype.services = {
  getPais,
  getDepartamento,
  getMunicipio,
  getBusquedaAnuncio,
  getCheckfavorite,
  setAddFavorite,
  getSubCategory,
  getMostadds,
  getServices,
  login,
  register,
  getAdsPosted,
  getServicesPosted,
  getfavoriteAds,
  setchangeStatusPublication,
  getGalleryAds,
  getRating,
  getDatailAd,
  datailAd,
  getMunicipality,
  setAnuncio,
  deleteFhot,
  loginApi,
  getInfoOtherUser,
  complaintReason,
  createComplaintReason,
  createCommentary,
  getUserInbox,
  datailService,
  getGalleryService,
  getMarcaSegunTipoVehiculo,
  forgotPassword,
  otpVerify,
  passwordChangeRecovery,
  updateAdditionalInfo
}