<template>
<footer >
    <img class="fnb__logoFooter mt-3" :src="src" alt="Logo Publikt purpura">
    <!--=============Link para acceder===================-->
    <div class="fnb__access d-flex justify-content-start flex-column">
        <p class="fnb--tittle">Acceder</p>
        <hr class="separator">
        <a class="fnb--link" href="/Login" v-show="!isLog">Inicio de sesión</a>
        <p class="fnb--link"  @click="logout()" v-show="isLog">Cerrar de sesión</p>
        <a class="fnb--link" href="/Login?action=registro">Registro</a>
    </div>
    <!--=============link informacion extra===================-->
    <div class="fnb__others d-flex justify-content-start flex-colum">
        <p class="fnb--tittle">Ayuda &#38; Soporte</p>
        <hr class="separator">
        <v-btn class="fnb--link pa-0 text-none" plain :to="{ name: 'privacyPolicies' }">Políticas de privacidad</v-btn>
        <v-btn class="fnb--link  pa-0 text-none" plain :to="{ name: 'termsConditions' }">Términos y Condiciones</v-btn>
        <a class="fnb--link" href="">Contáctanos</a>
    </div>
    <!--=============link redes sociales===================-->
    <div class="fnb__social d-flex justify-content-start flex-colum">
        <p class="fnb--tittle">Siguenos</p>
        <hr class="separator">
        <div class="fnb__social--icons d-flex justify-content-between">
            <a class="fnb--linkIcon" href="https://www.facebook.com/Publiktapp" target="_blank" title="Facebook"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg></a>
            <a class="fnb--linkIcon" href="https://www.instagram.com/Publiktapp" target="_blank" title="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
            <a class="fnb--linkIcon" href="https://twitter.com/PubliktStore" target="_blank" title="Twitter"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg></a>
        </div>
    </div>
</footer>
</template>

<script>
import { mapMutations } from 'vuex'
import VueCookie from 'vue-cookie'
export default {
  name: 'Footer',
  data: function () {
    return {
      src: require('../assets/img/logoPurpura.png'),
      isLog: false
    }
  },
  methods: {
    ...mapMutations(['setDataUser']),
    async logout () {
      if (this.isLog) {
        await this.$gAuth.signOut()
        this.$cookie.delete('token')
        this.$cookie.delete('userLogged')
        this.setDataUser([])
        localStorage.clear()
        this.$router.push('/').catch((e) => {})
      }
      this.$router.push('/Login')
    }
  },
  watch: {
    $route: {
      handler (val) {
        const isLogged = VueCookie.get('token')
        if (isLogged) {
          this.isLog = true
        } else {
          this.isLog = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
    width: 100vw;
    height: auto;
    background: #FBFAFB;
    padding-top: 1.2em;
    display: flex;
    margin: 0px;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 1em;
}

.fnb__logoFooter {
    width: 16em;
    height: 6em;
}

.fnb--tittle {
    font-size: 1.2em;
    font-weight: 600;
    color: #0f0f0f66;
    margin: 0px !important;
}

.fnb--link {
    text-shadow: none;
    color: #0f0f0f66 !important;
    text-decoration: none !important;
    font-size: 1.1em;
    margin-bottom: 0px;
    cursor: pointer;
}

.fnb--link:link{
    color: #0f0f0f66 !important;
}

.fnb--link:hover{
    color: #CC33CC !important;
}

.separator{
    border: solid 3px #0f0f0f66;
    margin: 0px;
    margin-bottom: 1em;
    width: 100%;
}

.fnb--linkIcon{
    width: 3em;
    height: 3em;
    border-radius: 100%;
    background: #0f0f0f66;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.fnb--linkIcon:hover {
    background: #CC33CC !important;
}

.fnb--linkIcon > svg {
    width: 1.5em;
    height: 1.5em;
    fill: white;
}

.fnb__access, .fnb__others, .fnb__social {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

@media (max-width: 960px) {
    footer {
    width: 100%;
    flex-direction: column !important;
    padding: 1em;
    padding-bottom: 4em;
    padding-left: 2em;
    }

    .fnb__logoFooter {
    width: 6em;
    height: 2em;
    }

    .fnb--tittle {
    font-size: 1em;
    }

    .fnb--link {
    font-size: 0.9em;
    }

    .separator{
    border: solid 1px #0f0f0f66;
    width: 40%;
    }

    .fnb__access, .fnb__others, .fnb__social {
    margin-top: 1em;
    }
}
</style>