/* eslint-disable space-before-function-paren */
/* eslint-disable eol-last */
/* eslint-disable indent */
import { getField, updateField } from 'vuex-map-fields'
import Vue from 'vue'
import axios from 'axios'
import { urlBase } from '../assets/js/base_url'
import { searchObj } from '../components/componentsAdd/searchInObject'

// import FirebaseImg from '../components/componentsAdd/FirebaseImg'
// const firebaseImg = new FirebaseImg()

export default {
    namespaced: true,
    valid: true,
    state: {
        formulario: '',
        selectPath: [{
                form: 'Ads',
                path: 'general/categories/2',
                pathSet: 'anuncio/insertAnuncio',
                pathFirebse: 'ads'
            },
            {
                form: 'Adsvehicle',
                path: 'anuncio/marcaVehicle',
                pathSet: 'anuncio/insertAnuncio',
                pathFirebse: 'vehicle'
            },
            {
                form: 'Services',
                path: 'general/categories/1',
                pathSet: 'servicio/addService',
                pathFirebse: 'service'
            },
            {
                form: 'Goods',
                path: '',
                pathSet: 'anuncio/insertAnuncio',
                pathFirebse: 'goods'
            }
        ],
        product: {},
        File: [],
        userInfoBeforeSet: [],
        userInfoBySetPhoto: {},
        categories: [],
        subCategories: [],
        municipio: [],
        searchMunicipio: '',
        currency: [],
        statusProduct: [],
        urlImage: [],
        // validaciones //
        productRules: [
            v => !!v || 'Título es requerido',
            v => (v && v.length <= 50) || 'Título debe contener máximo 50 caracteres'
        ],
        descriptionRules: [
            v => !!v || 'Descripción es requerida',
            v => (v && v.length <= 500) || 'Descripción debe contener máximo 500 caracteres'
        ],
        selectRules: [v => !!v || 'Selección requerida'],
        campoRules: [
            v => !!v || 'Campo requerido'
            // v => v > 0 || 'El precio no puede ser 0'
        ],
        anioRules: [
            v => !!v || 'Campo requerido',
            v => v > 1979 || 'El año debe de ser igual o mayor a 1980',
            v => v < 2024 || 'El año no puede ser mayor a 2023'
        ],
        imageRules: [
                v => !!v || 'Campo requerido',
                v => (v && v.length <= 500) || 'Debe de subir 1 imagen como minimo'
            ]
            // validaciones //
    },
    getters: {
        // Add the `getField` getter to the
        // `getters` of your Vuex store instance.
        getField
    },
    mutations: {
        // Add the `updateField` mutation to the
        // `mutations` of your Vuex store instance.
        updateField,
        submitAction(state) {
            if (state.userInfoBySetPhoto.url.length > 0) {
                axios.post(`${urlBase()}general/insertFoto`, state.userInfoBySetPhoto).then((e) => {
                    state.File = []
                    Vue.prototype.firebaseImg.successAddProduct()
                })
            }
            state.product = {}
            state.File = []
            Vue.prototype.firebaseImg.successAddProduct()
        },
        setCateories(state, payload) {
            state.categories = payload.data.filter(categoria => {
               return ![2].includes(categoria.IdCategoria)
            })
        },
        setSubCateories(state, payload) {
            if (payload) {
                const url = `anuncio/subCategories/${payload}`
                    // state.formulario === 'Adsvehicle' ? `anuncio/modeloVehicle/${payload}` : `anuncio/subCategories/${payload}`
                axios.get(`${urlBase()}${url}`)
                    .then((resp) => {
                        state.subCategories = resp.data
                    })
            }
        },
        searchMunicipality(state, payload) {
            state.municipio = payload.data
        },
        setMoneda(state) {
            axios.get(`${urlBase()}general/moneda`).then((resutl) => {
                state.currency = resutl.data
            })
        },
        setStatus(state) {
            axios.get(`${urlBase()}anuncio/estado`).then((result) => {
                state.statusProduct = result.data
            })
        },
        setMarcaVehiculo (state, payload) {
            state.categories = payload
        }
    },
    actions: {
        async submitAction({ commit, state }) {
            const url = searchObj(state.selectPath, state.formulario)
            const infoUser = await Vue.prototype.services.setAnuncio(url.pathSet, state.product)
            state.userInfoBeforeSet = infoUser.data
                if (state.File.length !== 0) {
                    const urlImage = await Vue.prototype.firebaseImg.addPhoto(state.File, { infoAnuncio: infoUser.data.data, userId: infoUser.data.userId }, url.pathFirebse)
                    state.userInfoBySetPhoto = urlImage
                }
                commit('submitAction')
        },
        async setCateories({ commit, state }, val) {
            const url = searchObj(state.selectPath, val)
            const cat = await axios.get(`${urlBase()}${url.path}`)
            commit('setCateories', cat)
        },
        setSubCateories({ commit }, val) {
            commit('setSubCateories', val)
        },
        async searchMunicipality({ commit }, val) {
            if (val) {
                const response = await Vue.prototype.services.getMunicipality(val)
                commit('searchMunicipality', response)
            }
        },
        setMoneda({ commit }) {
            commit('setMoneda')
        },
        setStatus({ commit }) {
            commit('setStatus')
        }
    }
}