<template>
  <v-bottom-navigation app :value="value" color="#cc33cc">
    <v-btn to="/" icon max-width="60">
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn to="/profile/favorites" icon max-width="60">
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn to="/producto/Productos" icon dark v-bind="attrs" v-on="on">
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </template>
    </v-menu>

    <v-btn to="/chat" icon>
      <v-icon>mdi-wechat</v-icon>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn to="/Login" icon dark v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
    </v-menu>
  </v-bottom-navigation>
</template>
<script>
export default {
  data: () => ({ value: 0 })
}
</script>
<style lang="scss" scoped>
  .v-btn{
   .v-icon{
    transition: 0.2s ease-in-out;
    font-size: 2em;
    border-radius: 1em;
   }
  }

  .v-btn--active{
    .v-icon{
    font-size: 2.3em;
    transition: 0.2s ease-in-out;
    background: linear-gradient(to bottom right, #cc33cc54, #ffffff3a);
    width: 1.7em;
    height: 1.7em;
    border-radius: 0.3em;
   }
  }
</style>